import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import litraBase from './seatmap-images/bpa75_response.png';
import litraWithSeats from './seatmap-images/bpa75_ex1.png';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "seatmap"
    }}>{`Seatmap`}</h1>
    <p>{`Seatmap consists of two parts, a REST API for administration/configuration of seat maps and an API for fetching seat map images.
For Seatmap to work the seat allocation system Plads90 must be set up and the railcar identification 'litra' must be the same in Plads90 as in Seatmap.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`Seatmap requires a Bearer Token in the Authorization header created by the Entur Authentication server for every request.`}</p>
    <h2 {...{
      "id": "seatmap-api"
    }}>{`Seatmap API`}</h2>
    <p>{`Seatmap API are services for fetching metadata and images for seat maps. The image formats supported is PNG (raster image) and SVG (Scalable Vector Graphics).`}</p>
    <h1 {...{
      "id": "guides"
    }}>{`Guides`}</h1>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#prerequisites"
        }}>{`Prerequisites`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#seatmap-api"
        }}>{`Seatmap API`}</a>
        <ol parentName="li">
          <li parentName="ol"><a parentName="li" {...{
              "href": "#usage"
            }}>{`Usage`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "#api"
            }}>{`API`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "#example"
            }}>{`Example`}</a></li>
        </ol>
      </li>
    </ol>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <p>{`For Seatmap to work the seat allocation system Plads90 must be set up and the railcar identification 'litra' must be the same in Plads90 as in Seatmap.`}</p>
    <h2 {...{
      "id": "seatmap-api-1"
    }}>{`Seatmap API`}</h2>
    <p>{`The three services in this API are used by agents and operators to fetch metadata and images for seatmaps.`}</p>
    <h3 {...{
      "id": "usage"
    }}>{`Usage`}</h3>
    <p>{`The first step when starting to use Seatmap API is to call get /api/railcar_sets/YOUR-SETCODE/railcars, this call will make Seatmap generate all images
needed. The response will list all railcars, with matadata and image path, in the railcar set and all railcar elements for each railcar,
also with metadata and paths to images. Railcar elements with the "seat" parameter set to true will have links to images/icons for each
status(FREE, CHOSEN, UNAVAILABLE, CONFIRMED AND CONFLICT).`}</p>
    <p>{`Next step is to call get /api/railcar_sets/YOUR-SETCODE/railcars/LITRA with train number and from station number/NRS code
('NSR:StopPlace:609' - Kristiansand) as request parameters to get information about wanted railcar and it's railcar elements.`}</p>
    <p>{`Then a call to get /api/railcar_sets/YOUR-SETCODE/railcars/LITRA.png with the same request parameters as above to get the "base image"
for the wanted railcar.`}</p>
    <p>{`The reason for giving train number and from station number/NSR code is the possibility that one or both of them are included in a train
exception rule or a station exception rule. Train exception rules contains information whether an interval of train numbers (a line)
have seatmaps enabled and whether that line have flip direction of seatmap for railcar enabled. Station exception rules tells whether
flip direction of seatmap for railcar should be implementet on this station (i.e. Kristiansand where the train arrives the station in
one direction and leaves in the other direction even though it's not a terminus).`}</p>
    <p>{`Combined with information about seat availability for a given railcar on an given departure and "base image", seatnumber, position x,
position y and wanted image/icon based on seat status from Seatmap a complete seatmap can be made.`}</p>
    <h3 {...{
      "id": "api"
    }}>{`API`}</h3>
    <ExpandablePanel title="GET /api/railcar_sets/:setCode/railcars" mdxType="ExpandablePanel">
Example response GET /api/railcar_sets/Entur_v1/railcars
      <pre>
        {`
    [
        {
            "railcarSetId": 1,
            "imageContentType": "image/png",
            "imageUrlPath": "/images/seatmaps/1/BPA75_93x607.png?sec=1544443720",
            "id": 122,
            "litraCode": "BPA75",
            "imageWidth": 93,
            "imageHeight": 607,
            "rotated": false,
            "railcarElements": [
                {
                    "id": 5155,
                    "seatNumber": 44,
                    "positionX": 74,
                    "positionY": 143,
                    "direction": "REARWARD",
                    "iconType": {
                        "id": 2,
                        "typeName": "REAR_FACING_SEAT",
                        "seat": true,
                        "nameNor": "Bakovervendt sete",
                        "nameEng": "Rear facing seat",
                        "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                        "imageWidth": 15,
                        "imageHeight": 21,
                        "imageContentType": "image/png",
                        "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                        "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                        "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                        "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                    }
                },
                {}....
            ]
        },
        {}....
    ]
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="GET /api/railcar_sets/:setCode/railcars/:litraCode" mdxType="ExpandablePanel">
Example response GET /api/railcar_sets/Entur_v1/railcars/BPA75?trainNumber=701&fromStationNSRCode=NSR:StopPlace:596
      <pre>
        {`
    {
        "railcarSetId": 1,
        "imageContentType": "image/png",
        "imageUrlPath": "/images/seatmaps/1/BPA75_93x607.png?sec=1544443720",
        "id": 122,
        "litraCode": "BPA75",
        "imageWidth": 93,
        "imageHeight": 607,
        "rotated": false,
        "railcarElements": [
            {
                "id": 5155,
                "seatNumber": 44,
                "positionX": 74,
                "positionY": 143,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageWidth": 15,
                    "imageHeight": 21,
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {}....
        ]
    }
`}
      </pre>
    </ExpandablePanel>
    <ExpandablePanel title="GET /api/railcar_sets/:setCode/railcars/:litraCode.:imgExt" mdxType="ExpandablePanel">
      <figure>
    <img src={litraBase} alt="Example response" />
    <figcaption>Response for base layout without seats</figcaption>
      </figure>
    </ExpandablePanel>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <p>{`Step by step example:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Call get /api/railcar_sets/Entur_v1/railcars to get information about railcars in set and if first call get Seatmap
to generate images.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Call get /api/railcar_sets/Entur_v1/railcars/BPA75 to get information about railcar with litra 'BPA75',
`}<a parentName="p" {...{
            "href": "#railcar-bpa75-response"
          }}>{`see complete response below`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Call get /api/railcar_sets/Entur_v1/railcars/BPA75.png to get the "base image" for railcar with litra 'BPA75'.`}</p>
        <figure>
    <img src={litraBase} alt="Example response" />
    <figcaption>Response for base layout without seats</figcaption>
        </figure>
      </li>
      <li parentName="ol">
        <p parentName="li">{`External information all seats excluding seat number 44, 45, 49 and 50 are free, and the four mentioned are unavailable.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Combine step 2, 3 and 4 to generate seatmap for railcar with litra 'BPA75''.`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`"Base image" as first layer.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`For each railcar element use seatNumber, positionX and positionY (where x 0 and y 0 is top left corner) with
external seat status information and wanted icon to put icon on top of "base image".`}</p>
            <pre parentName="li"><code parentName="pre" {...{}}>{`1. Seat 44 is unavailable -> place icon from imageUrlPathUnavailable (/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324)
on coordinates x=74 and y=143.

2. Seat 45 is unavailable -> place icon from imageUrlPathUnavailable (/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324)
on coordinates x=58 and y=143.

3. Seat 46 is free -> place icon from imageUrlPath (/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355)
on coordinates x=35 and y=143.

4. Etc.
`}</code></pre>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Final result`}</p>
        <figure>
    <img src={litraWithSeats} alt="Railcar with seats" />
    <figcaption>
        Railcar base and added seats placed correctly within the base layout
    </figcaption>
        </figure>
      </li>
    </ol>
    <h4 {...{
      "id": "railcar-bpa75-response"
    }}>{`Railcar BPA75 response`}</h4>
    <ExpandablePanel title="Response" mdxType="ExpandablePanel">
    <pre>
        {`
    {
        "railcarSetId": 1,
        "imageContentType": "image/png",
        "imageUrlPath": "/images/seatmaps/1/BPA75_93x607.png?sec=1544443720",
        "id": 122,
        "litraCode": "BPA75",
        "imageWidth": 93,
        "imageHeight": 607,
        "rotated": false,
        "railcarElements": [
            {
                "id": 5155,
                "seatNumber": 44,
                "positionX": 74,
                "positionY": 143,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5165,
                "seatNumber": 45,
                "positionX": 58,
                "positionY": 143,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5168,
                "seatNumber": 46,
                "positionX": 35,
                "positionY": 143,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5151,
                "seatNumber": 47,
                "positionX": 20,
                "positionY": 143,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5159,
                "seatNumber": 48,
                "positionX": 6,
                "positionY": 143,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5167,
                "seatNumber": 49,
                "positionX": 74,
                "positionY": 175,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5161,
                "seatNumber": 50,
                "positionX": 58,
                "positionY": 175,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5153,
                "seatNumber": 51,
                "positionX": 35,
                "positionY": 168,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5157,
                "seatNumber": 52,
                "positionX": 20,
                "positionY": 168,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5163,
                "seatNumber": 53,
                "positionX": 6,
                "positionY": 168,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5154,
                "seatNumber": 54,
                "positionX": 74,
                "positionY": 200,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5164,
                "seatNumber": 55,
                "positionX": 58,
                "positionY": 200,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5158,
                "seatNumber": 56,
                "positionX": 35,
                "positionY": 193,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5162,
                "seatNumber": 57,
                "positionX": 20,
                "positionY": 193,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5169,
                "seatNumber": 58,
                "positionX": 6,
                "positionY": 193,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5243,
                "seatNumber": 59,
                "positionX": 74,
                "positionY": 225,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5246,
                "seatNumber": 60,
                "positionX": 58,
                "positionY": 225,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5238,
                "seatNumber": 61,
                "positionX": 35,
                "positionY": 218,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5240,
                "seatNumber": 62,
                "positionX": 20,
                "positionY": 218,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5235,
                "seatNumber": 63,
                "positionX": 6,
                "positionY": 218,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5239,
                "seatNumber": 64,
                "positionX": 74,
                "positionY": 250,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5245,
                "seatNumber": 65,
                "positionX": 58,
                "positionY": 250,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5241,
                "seatNumber": 66,
                "positionX": 35,
                "positionY": 243,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5244,
                "seatNumber": 67,
                "positionX": 20,
                "positionY": 243,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5236,
                "seatNumber": 68,
                "positionX": 6,
                "positionY": 243,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5237,
                "seatNumber": 69,
                "positionX": 74,
                "positionY": 275,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5242,
                "seatNumber": 70,
                "positionX": 58,
                "positionY": 275,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5247,
                "seatNumber": 71,
                "positionX": 35,
                "positionY": 275,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5248,
                "seatNumber": 72,
                "positionX": 20,
                "positionY": 275,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5234,
                "seatNumber": 73,
                "positionX": 6,
                "positionY": 275,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5265,
                "seatNumber": 74,
                "positionX": 74,
                "positionY": 425,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5261,
                "seatNumber": 75,
                "positionX": 58,
                "positionY": 425,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5255,
                "seatNumber": 76,
                "positionX": 35,
                "positionY": 425,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5258,
                "seatNumber": 77,
                "positionX": 20,
                "positionY": 425,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5264,
                "seatNumber": 78,
                "positionX": 6,
                "positionY": 425,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5253,
                "seatNumber": 79,
                "positionX": 74,
                "positionY": 450,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5250,
                "seatNumber": 80,
                "positionX": 58,
                "positionY": 450,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5268,
                "seatNumber": 81,
                "positionX": 35,
                "positionY": 458,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5260,
                "seatNumber": 82,
                "positionX": 20,
                "positionY": 458,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5256,
                "seatNumber": 83,
                "positionX": 6,
                "positionY": 458,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5269,
                "seatNumber": 84,
                "positionX": 74,
                "positionY": 475,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5266,
                "seatNumber": 85,
                "positionX": 58,
                "positionY": 475,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5274,
                "seatNumber": 86,
                "positionX": 35,
                "positionY": 483,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5276,
                "seatNumber": 87,
                "positionX": 20,
                "positionY": 483,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5267,
                "seatNumber": 88,
                "positionX": 6,
                "positionY": 483,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5259,
                "seatNumber": 89,
                "positionX": 74,
                "positionY": 500,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5254,
                "seatNumber": 90,
                "positionX": 58,
                "positionY": 500,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5263,
                "seatNumber": 91,
                "positionX": 35,
                "positionY": 508,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5257,
                "seatNumber": 92,
                "positionX": 20,
                "positionY": 508,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5251,
                "seatNumber": 93,
                "positionX": 6,
                "positionY": 508,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5271,
                "seatNumber": 94,
                "positionX": 74,
                "positionY": 525,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5270,
                "seatNumber": 95,
                "positionX": 58,
                "positionY": 525,
                "direction": "REARWARD",
                "iconType": {
                    "id": 2,
                    "typeName": "REAR_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Bakovervendt sete",
                    "nameEng": "Rear facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/REAR_FACING_SEAT$FREE_15x21.png?sec=1453291355",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/REAR_FACING_SEAT$CHOSEN_15x21.png?sec=1564409955",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/REAR_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1461251324",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/REAR_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497194",
                    "imageUrlPathConflict": "/images/seatmaps/1/REAR_FACING_SEAT$CONFLICT_15x21.png?sec=1477497207"
                }
            },
            {
                "id": 5272,
                "seatNumber": 96,
                "positionX": 35,
                "positionY": 533,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5273,
                "seatNumber": 97,
                "positionX": 20,
                "positionY": 533,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5262,
                "seatNumber": 98,
                "positionX": 6,
                "positionY": 533,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5275,
                "seatNumber": 99,
                "positionX": 74,
                "positionY": 558,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5249,
                "seatNumber": 100,
                "positionX": 58,
                "positionY": 558,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5252,
                "seatNumber": 101,
                "positionX": 20,
                "positionY": 558,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5277,
                "seatNumber": 102,
                "positionX": 6,
                "positionY": 558,
                "direction": "FORWARD",
                "iconType": {
                    "id": 1,
                    "typeName": "FORWARD_FACING_SEAT",
                    "seat": true,
                    "nameNor": "Fremovervendt sete",
                    "nameEng": "Forward facing seat",
                    "imageHeight": 21,
                    "imageWidth": 15,
                    "imageUrlPath": "/images/seatmaps/1/FORWARD_FACING_SEAT$FREE_15x21.png?sec=1453291262",
                    "imageContentType": "image/png",
                    "imageUrlPathChosen": "/images/seatmaps/1/FORWARD_FACING_SEAT$CHOSEN_15x21.png?sec=1564409890",
                    "imageUrlPathUnavailable": "/images/seatmaps/1/FORWARD_FACING_SEAT$UNAVAILABLE_15x21.png?sec=1453291289",
                    "imageUrlPathConfirmed": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFIRMED_15x21.png?sec=1477497187",
                    "imageUrlPathConflict": "/images/seatmaps/1/FORWARD_FACING_SEAT$CONFLICT_15x21.png?sec=1477497203"
                }
            },
            {
                "id": 5160,
                "seatNumber": null,
                "positionX": 4,
                "positionY": 94,
                "direction": "UNKNOWN",
                "iconType": {
                    "id": 21,
                    "typeName": "EXIT_LEFT",
                    "seat": false,
                    "nameNor": "Utgang venstre",
                    "nameEng": "Exit left",
                    "imageHeight": 26,
                    "imageWidth": 12,
                    "imageUrlPath": "/images/seatmaps/1/EXIT_LEFT_12x26.png?sec=1479736006",
                    "imageContentType": "image/png"
                }
            },
            {
                "id": 5278,
                "seatNumber": null,
                "positionX": 25,
                "positionY": 295,
                "direction": "UNKNOWN",
                "iconType": {
                    "id": 10,
                    "typeName": "BIKE",
                    "seat": false,
                    "nameNor": "Sykkelplass",
                    "nameEng": "Bicycle space",
                    "imageHeight": 16,
                    "imageWidth": 16,
                    "imageUrlPath": "/images/seatmaps/1/BIKE_16x16.png?sec=1564407881",
                    "imageContentType": "image/png"
                }
            },
            {
                "id": 5279,
                "seatNumber": null,
                "positionX": 75,
                "positionY": 340,
                "direction": "UNKNOWN",
                "iconType": {
                    "id": 9,
                    "typeName": "HOT_DRINKS",
                    "seat": false,
                    "nameNor": "NSB Meny Automat",
                    "nameEng": "Vending machine",
                    "imageHeight": 16,
                    "imageWidth": 16,
                    "imageUrlPath": "/images/seatmaps/1/HOT_DRINKS_16x16.png?sec=1464091837",
                    "imageContentType": "image/png"
                }
            },
            {
                "id": 5280,
                "seatNumber": null,
                "positionX": 25,
                "positionY": 340,
                "direction": "UNKNOWN",
                "iconType": {
                    "id": 14,
                    "typeName": "STROLLER",
                    "seat": false,
                    "nameNor": "Barnevognplass",
                    "nameEng": "Slot for stroller",
                    "imageHeight": 16,
                    "imageWidth": 16,
                    "imageUrlPath": "/images/seatmaps/1/STROLLER_16x16.png?sec=1464091760",
                    "imageContentType": "image/png"
                }
            },
            {
                "id": 5152,
                "seatNumber": null,
                "positionX": 70,
                "positionY": 25,
                "direction": "UNKNOWN",
                "iconType": {
                    "id": 5,
                    "typeName": "WC",
                    "seat": false,
                    "nameNor": "Toalett",
                    "nameEng": "Toilet",
                    "imageHeight": 16,
                    "imageWidth": 16,
                    "imageUrlPath": "/images/seatmaps/1/WC_16x16.png?sec=1464091792",
                    "imageContentType": "image/png"
                }
            },
            {
                "id": 5166,
                "seatNumber": null,
                "positionX": 78,
                "positionY": 374,
                "direction": "UNKNOWN",
                "iconType": {
                    "id": 22,
                    "typeName": "EXIT_RIGHT",
                    "seat": false,
                    "nameNor": "Utgang høyre",
                    "nameEng": "Exit right",
                    "imageHeight": 26,
                    "imageWidth": 12,
                    "imageUrlPath": "/images/seatmaps/1/EXIT_RIGHT_12x26.png?sec=1479735938",
                    "imageContentType": "image/png"
                }
            },
            {
                "id": 5170,
                "seatNumber": null,
                "positionX": 78,
                "positionY": 94,
                "direction": "UNKNOWN",
                "iconType": {
                    "id": 22,
                    "typeName": "EXIT_RIGHT",
                    "seat": false,
                    "nameNor": "Utgang høyre",
                    "nameEng": "Exit right",
                    "imageHeight": 26,
                    "imageWidth": 12,
                    "imageUrlPath": "/images/seatmaps/1/EXIT_RIGHT_12x26.png?sec=1479735938",
                    "imageContentType": "image/png"
                }
            },
            {
                "id": 5156,
                "seatNumber": null,
                "positionX": 4,
                "positionY": 374,
                "direction": "UNKNOWN",
                "iconType": {
                    "id": 21,
                    "typeName": "EXIT_LEFT",
                    "seat": false,
                    "nameNor": "Utgang venstre",
                    "nameEng": "Exit left",
                    "imageHeight": 26,
                    "imageWidth": 12,
                    "imageUrlPath": "/images/seatmaps/1/EXIT_LEFT_12x26.png?sec=1479736006",
                    "imageContentType": "image/png"
                }
            }
        ]
    }
`}
    </pre>
    </ExpandablePanel>
    <h1 {...{
      "id": "api-reference"
    }}>{`API Reference`}</h1>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/seatmap/v1/v3/api-docs"
      }}>{`See the API reference on the Swagger Petstore - production`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/seatmap/v1/v3/api-docs"
      }}>{`See the API reference on the Swagger Petstore - staging`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://api.entur.io/seatmap/v1/swagger-ui.html#/Seatmap32API"
      }}>{`Swagger API reference - production`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://api.staging.entur.io/seatmap/v1/swagger-ui.html#/Seatmap32API"
      }}>{`Swagger API reference - staging`}</a></p>
    <h2 {...{
      "id": "staging"
    }}>{`Staging`}</h2>
    <Swagger url="https://api.staging.entur.io/seatmap/v1/v3/api-docs" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      